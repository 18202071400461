import React, { useEffect } from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import CarosuelPage from "./Carosuel";
import KeyFeaturesSection from "../AboutUS/KeyFeatures";
import { Divider, Typography } from "@mui/material";
import CardPage from "./ProductsCardnew";
import KeyApplicationsSection from "../AboutUS/KeyApplication";

import NoticeBar from "./noticeBar";
import ClientList from "./ClientList";
import { useLocation } from "react-router-dom";

const brandNames = ["Gautham", "Waaree", "Vikram", "Brand D", "Brand E"];

const HomePage = () => {
  // const { state: data } = location;
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams?.get("source");
    if (source == "Product") {
      window.scrollTo(0, 1300);
    }
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <CarosuelPage />
      </Grid>

      {/* <Grid item xs={12}>
        <NoticeBar brandNames={brandNames} />
      </Grid> */}

      <Grid item xs={12}>
        <KeyFeaturesSection />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <CardPage />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} spacing={2}>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "40px",
            marginTop: "10px",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          OUR MAJOR CLIENTS
        </Typography>
        <ClientList />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <KeyApplicationsSection />
      </Grid>
    </Grid>
  );
};

export default HomePage;
