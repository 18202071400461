// import React, { useEffect } from "react";
// import { Grid } from "@mui/material";
// import tubularImage from "./image.png";
// import TubularContent from "./TubularContent";
// import BatteryTable from "./TubularTable";
// import batteryDetail from "./BatteryContent.json";
// import headings from "./BatteryContent.json";
// import subColumns from "./BatteryContent.json";
// import itemData from "./BatteryContent.json";
// import Table from "./Table";
// import BatteryCont from "./BatteryCont";
// import batteryCareTips from "./BatteryContent.json";

// const Batteries = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const {
//     item,
//     product,
//     batterySpecifications,
//     columnHeadings1,
//     subColumns1,
//     columnHeadings2,
//     batteryDischargeCharacteristics,
//     ratedCapacities,
//     columnHeadings3,
//     batteryCareTips,
//   } = itemData;

//   console.log(item);
//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <img
//           src={tubularImage}
//           alt="Tubular Image"
//           style={{ width: "100%", height: "100%" }}
//         />
//       </Grid>
//       <Grid item xs={12}>
//         <TubularContent product={product} />
//       </Grid>
//       <Grid item xs={12}>
//         <BatteryTable
//           batteryDetails={batteryDetail.batteryDetails}
//           columnHeadings={headings.columnHeadings}
//           subColumns={subColumns.subColumns}
//         />
//       </Grid>
//       <Grid item xs={12} style={{ paddingleft: "20px" }}>
//         <TubularContent product={item} />
//       </Grid>
//       <Grid item xs={12}>
//         <h2 style={{ textAlign: "center" }}>
//           {" "}
//           Tower Series of Future Generation Tubular Batteries Specifications:
//         </h2>
//         <BatteryTable
//           batteryDetails={batterySpecifications}
//           columnHeadings={columnHeadings1}
//           subColumns={subColumns1}
//         />
//       </Grid>

//       <Grid item xs={12}>
//         <h2 style={{ textAlign: "center", marginBottom: "-1em" }}>
//           Tubular Battery Discharge Characteristics
//         </h2>
//         <h3 style={{ textAlign: "center" }}>Ex. 100 Ah Battery</h3>

//         <Table
//           data={batteryDischargeCharacteristics}
//           columnHeadings={columnHeadings2}
//         />
//       </Grid>
//       <Grid item xs={12}>
//         <h2 style={{ textAlign: "center" }}>
//           Rated Capacities at 27 degree C in Ah:
//         </h2>
//         <Table data={ratedCapacities} columnHeadings={columnHeadings3} />
//       </Grid>
//       <Grid item xs={12} style={{ paddingLeft: "20px" }}>
//         <BatteryCont batteryCareTips={batteryCareTips} />
//       </Grid>
//     </Grid>
//   );
// };
// export default Batteries;

import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import tubularImage from "./image.png";
import TubularContent from "./TubularContent";
import BatteryTable from "./TubularTable";
import batteryDetail from "./BatteryContent.json";
import headings from "./BatteryContent.json";
import subColumns from "./BatteryContent.json";
import itemData from "./BatteryContent.json";
import Table from "./Table";
import BatteryCont from "./BatteryCont";
import batteryCareTips from "./BatteryContent.json";

const Batteries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {
    item,
    product,
    batterySpecifications,
    columnHeadings1,
    subColumns1,
    columnHeadings2,
    batteryDischargeCharacteristics,
    ratedCapacities,
    columnHeadings3,
    batteryCareTips,
  } = itemData;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src={tubularImage}
          alt="Tubular Image"
          style={{ width: "100%", height: "100%" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TubularContent product={product} />
      </Grid>
      <Grid item xs={12}>
        <BatteryTable
          batteryDetails={batteryDetail.batteryDetails}
          columnHeadings={headings.columnHeadings}
          subColumns={subColumns.subColumns}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingleft: "20px" }}>
        <TubularContent product={item} />
      </Grid>
      <Grid item xs={12}>
        <h2 style={{ textAlign: "center" }}>
          {" "}
          Tower Series of Future Generation Tubular Batteries Specifications:
        </h2>
        <BatteryTable
          batteryDetails={batterySpecifications}
          columnHeadings={columnHeadings1}
          subColumns={subColumns1}
        />
      </Grid>

      <Grid item xs={12}>
        <h2 style={{ textAlign: "center", marginBottom: "-1em" }}>
          Tubular Battery Discharge Characteristics
        </h2>
        <h3 style={{ textAlign: "center" }}>Ex. 100 Ah Battery</h3>

        <Table
          data={batteryDischargeCharacteristics}
          columnHeadings={columnHeadings2}
        />
      </Grid>
      <Grid item xs={12}>
        <h2 style={{ textAlign: "center" }}>
          Rated Capacities at 27 degree C in Ah:
        </h2>
        <Table data={ratedCapacities} columnHeadings={columnHeadings3} />
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: "20px" }}>
        <BatteryCont batteryCareTips={batteryCareTips} />
      </Grid>
    </Grid>
  );
};
export default Batteries;
