import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const footerStyle = {
  background: "#1c548d",
  color: "white",
  marginTop: "10px",
};

const listItemStyle = {
  listStyle: "none",
};

const currentYear = new Date().getFullYear();

const Footer = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <footer style={footerStyle}>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          lg={9}
          md={10}
          sm={11}
          xs={12}
          spacing={2}
          style={{ marginTop: "20px" }}
        >
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <h3 style={{ paddingLeft: isMobile ? "10px" : "-30px" }}>
              Useful Links
            </h3>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                  transition: "text-decoration 0.3s",
                }}
                to="/products/lift"
              >
                Products
              </Link>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <a
                style={{
                  color: "white",
                  textDecoration:
                    hoveredLink === "Store Locator" ? "underline" : "none",
                  cursor: "pointer",
                  transition: "text-decoration 0.3s",
                }}
                href="/contact"
              >
                Store Locator
              </a>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/gallery"
              >
                Media
              </a>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/contact"
              >
                Enquire Now
              </a>
            </li>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <h3 style={{ paddingLeft: isMobile ? "10px" : "-30px" }}>
              {" "}
              Products
            </h3>

            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent", // Initially hide the underline
                  transition: "border-color 0.3s ease", // Smooth transition for the underline
                }}
                to="/products/lift"
                onMouseOver={(e) => (e.target.style.borderColor = "white")} // Show white underline on hover
                onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
              >
                Lifts or Offline UPS
              </Link>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent", // Initially hide the underline
                  transition: "border-color 0.3s ease", // Smooth transition for the underline
                }}
                onMouseOver={(e) => (e.target.style.borderColor = "white")} // Show white underline on hover
                onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
                to="/products/onlineUps"
              >
                True Online UPS
              </Link>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent", // Initially hide the underline
                  transition: "border-color 0.3s ease", // Smooth transition for the underline
                }}
                onMouseOver={(e) => (e.target.style.borderColor = "white")} // Show white underline on hover
                onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
                to="/products/sinewaveUps"
              >
                Sine Wave Solar Inverter/UPS
              </Link>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent", // Initially hide the underline
                  transition: "border-color 0.3s ease", // Smooth transition for the underline
                }}
                onMouseOver={(e) => (e.target.style.borderColor = "white")} // Show white underline on hover
                onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
                to="/products/battery"
              >
                Batteries
              </Link>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent", // Initially hide the underline
                  transition: "border-color 0.3s ease", // Smooth transition for the underline
                }}
                onMouseOver={(e) => (e.target.style.borderColor = "white")} // Show white underline on hover
                onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
                to="products/stabilizers"
              >
                Stabilizers
              </Link>
            </li>
            <li
              style={{
                fontSize: "16px",
                listStyle: "none",
                marginLeft: isMobile ? "15px" : "10px",
              }}
            >
              <Link
                style={{
                  color: "white",
                  textDecoration: "none",
                  borderBottom: "1px solid transparent", // Initially hide the underline
                  transition: "border-color 0.3s ease", // Smooth transition for the underline
                }}
                onMouseOver={(e) => (e.target.style.borderColor = "white")} // Show white underline on hover
                onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
                to="products/SolarRoof"
              >
                Solar Roof Top System
              </Link>
            </li>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <h3 style={{ paddingLeft: "10px" }}>Contact Info</h3>
            <p style={{ fontSize: "16px", paddingLeft: "20px" }}>
              Address: <br />
              Elites Power Solutions Pvt. Ltd. <br />
              # 459, Vaishnavi Enclave, <br />
              12th main, MC layout <br />
              Near Post Office, Bengaluru, <br />
              Karnataka.
            </p>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <h3 style={{ paddingLeft: isMobile ? "10px" : "30px" }}>Support</h3>
            <ul style={listItemStyle}>
              <li
                style={{
                  fontSize: "16px",
                  color: "white",
                  marginLeft: isMobile ? "-15px" : "",
                }}
              >
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="mailto:sales@elitespower.com"
                >
                  <u>Email</u>: sales@elitespower.com
                </a>
              </li>
              <li
                style={{
                  color: "white",
                  fontSize: "16px",
                  marginLeft: isMobile ? "-15px" : "",
                }}
              >
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:+918880123444"
                >
                  <u>Phone</u>: +91-8880123444
                </a>
              </li>
              <li
                style={{
                  color: "white",
                  fontSize: "16px",
                  marginLeft: isMobile ? "-15px" : "",
                }}
              >
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:+919686683821"
                >
                  <u>Helpline Number</u>: +91-9686683821
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          item
          lg={9}
          md={10}
          sm={11}
          xs={12}
          style={{
            marginTop: "10px",
            justifyContent: "space-between",
            marginBottom: "50px",
            marginLeft: isMobile ? "15px" : "",
          }}
        >
          <Typography style={{ fontSize: "12px", paddingLeft: "10px" }}>
            COPYRIGHT © {currentYear}. ALL RIGHTS RESERVED
          </Typography>
          <Typography style={{ fontSize: "12px", paddingLeft: "10px" }}>
            Website Developed by ByteCrafts
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
