import React from "react";
import { Grid } from "@mui/material";

const LiftDataDisplay = ({ jsonData }) => {
    const{companyInfo}=jsonData
  return (
    <Grid>
      <Grid item xs={12} >
        <h3>Company Information:</h3>
        <p><strong>Name:</strong> {jsonData.companyInfo.name}</p>
        <p><strong>Description:</strong> {jsonData.companyInfo.description}</p>
        <p><strong>Commitment:</strong> {jsonData.companyInfo.commitment}</p>
        <p><strong>Products:</strong> {jsonData.companyInfo.products.join(", ")}</p>
        <p><strong>Customer Service:</strong> {jsonData.companyInfo.customerService}</p>
        <p><strong>Technology:</strong> {jsonData.companyInfo.technology}</p>
      </Grid>

      <Grid item xs={12}>
        <h3>Service Information:</h3>
        <p><strong>Installation:</strong> {jsonData.companyInfo.service.installation.description}</p>
        <ul>
          {jsonData.companyInfo.service.installation.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <p><strong>AMC:</strong> {jsonData.companyInfo.service.AMC.description}</p>
      </Grid>
      </Grid>
  )

};

export default LiftDataDisplay;
