import React from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
// import data from './ListContent.json'


const Banner = ({data}) => {
  console.log(data)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fontSize = isSmallScreen ? '20px' : '35px';
  const bannerStyle = {
    // background: '#000',
    color: 'black',
    textAlign: isSmallScreen? 'left' :'center',
    padding: isSmallScreen? '10px' : '20px',
    width : isSmallScreen? '90%' :'100%'
  };

  const h1Style = {
    margin: '0',
    fontSize: fontSize,
    fontWeight: isSmallScreen? 'bold' : 'bolder',
  };
  const bannerTitle = data.bannerTitle;


  return (
    <div style={bannerStyle}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h1" style={h1Style}>
           
            {bannerTitle}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Banner;

