// App.jsx
import "./App.css";
import NavBar from "./AppBar/AppBar";
import AboutusPage from "./Views/AboutUS/AboutusPage";
import ContactUs from "./Views/ContactUs/ContactUs";
import HomePage from "./Views/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import Gallery from "./Views/gallery/Gallery";
import Lift from "./Views/products/lifts/Lifts";
import Services from "./Views/servies/Services";
import SineWaveUPs from "./Views/products/SineWaveUPs";
import Batteries from "./Views/products/Batteries/Batteries.jsx";
import Stabilizers from "./Views/products/Stabilizers";
import SolarRoof from "./Views/products/SolarRoof";
import Footer from "./Views/Footer";
import OnlineUps from "./Views/products/lifts/OnlineUPS";
import Lift1 from "./Views/products/lifts/Lifts1.jsx";
import Catelog from "./Views/Catelog/Catelog.jsx";
import Customers from "./Views/Customers.jsx"

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutusPage />} />

        <Route path="/products/lift" element={<Lift />} />
        <Route path="/products/onlineUps" element={<OnlineUps />} />
        <Route path="/products/lift1" element={<Lift1 />} />
        <Route path="/products/sinewaveUps" element={<SineWaveUPs />} />
        <Route path="/products/battery" element={<Batteries />} />
        <Route path="/products/stabilizers" element={<Stabilizers />} />
        <Route path="/products/SolarRoof" element={<SolarRoof />} />

        <Route path="/services" element={<Services />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/catelog" element={<Catelog />} />
        <Route path="/gallery/lift" element={<Gallery />} />
        <Route path="/gallery/ups" element={<Gallery />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/customers" element={<Customers />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
