import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import emailjs from "emailjs-com";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    telephone: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(
        "service_fq9hju9",
        "template_vnyvgsc",
        formData,
        "g-RFh1m7d2Pj18TiQ"
      );

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error sending email. Please try again.");
    }
    // Add your logic to handle form submission (e.g., send data to the server)
    console.log("Form submitted:", formData);
  };

  return (
    <Card
      elevation={4}
      style={{ maxWidth: "80%", margin: "auto", padding: 20 }}
    >
      <Typography variant="h5" gutterBottom>
        CONTACT US
      </Typography>
      <CardContent>
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextValidator
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextValidator
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextValidator
                label="Telephone"
                type="tel"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required", "isNumber", "matchRegexp:^\\d{10}$"]}
                errorMessages={[
                  "This field is required",
                  "Must be a number",
                  "Phone number must be 10 digits",
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextValidator
                label="City"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="How can we help you?"
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  marginTop: 20,
                  color: "white ",
                  backgroundColor: "#1c548d",
                  width: "100%",
                  border: "1px solid #1c548d ",
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </CardContent>
    </Card>
  );
};

export default ContactForm;
