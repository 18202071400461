import React, { useEffect } from "react";

import solarRoofImage from "./solarRoof4.jpeg";
import images from "./on-grid.jpeg";
import images1 from "./offgrid.webp";
import images2 from "./hybrid-solar-system-500x500.webp";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";

const SolarRoof = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="solar-top-roof">
      <Grid container justifyContent="center">
        <div className="main-image">
          <img
            src={solarRoofImage}
            alt="Main Solar Roof"
            style={{
              width: isSmallScreen ? "375px" : "1000px",
              height: isSmallScreen ? "400px" : "600px",
              marginBottom: "20px",
            }}
          />
        </div>
      </Grid>
      <Divider />
      <Grid style={{ padding: "10px" }}>
        <Typography
          variant="h4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          On-Grid Solar System
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <img
              src={images}
              alt="Left Side Image"
              style={{
                width: isSmallScreen ? "350px" : "600px",
                height: isSmallScreen ? "150px" : "300px",
                marginLeft: isSmallScreen ? "5px" : "20px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
              On-grid solar power system is a solar power generation system
              where it is connected to the utility grid. The electricity
              produced by the system is routed to the grid from where it is used
              to run the various appliances. The installation of the same is
              also fuss-free and easy to maintain.
            </Typography>
            <Typography
              style={{
                marginTop: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Benefits
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              <li>Save more money with net metering.</li>
              <br />
              <li>Lower upfront costs and ease of installation.</li>
              <br />
              <li>You can use the utility grid as a virtual battery.</li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid style={{ padding: "10px" }}>
        <Typography
          variant="h4"
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Off-Grid Solar System
        </Typography>
        {isSmallScreen ? (
          <>
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              style={{ marginTop: "20px", padding: "40px" }}
            >
              <Grid item xs={12} md={6}>
                <img
                  src={images1}
                  alt="Left Side Image"
                  style={{
                    width: isSmallScreen ? "350px" : "500px",
                    height: isSmallScreen ? "150px" : "300px",
                    marginLeft: isSmallScreen ? "5px" : "20px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  An off-grid solar system (off-the-grid, standalone) is the
                  obvious alternative to one that is grid-tied. For homeowners
                  that have access to the grid, off-grid solar systems are
                  usually out of question. Here’s why. To ensure access to
                  electricity at all times, off-grid solar systems require
                  high-capacity battery storage and a backup generator. On top
                  of this, a battery bank typically needs to be replaced after
                  10 years. Batteries are complicated, expensive, and decrease
                  overall system efficiency
                </Typography>
                <Typography
                  style={{
                    marginTop: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Benefits
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <li>
                    Can be installed where there is no access to the utility
                    grid.
                  </li>
                  <br />
                  <li>Become energy self-sufficient.</li>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </>
        ) : (
          <>
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={12} md={6}>
                <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
                  An off-grid solar system (off-the-grid, standalone) is the
                  obvious alternative to one that is grid-tied. For homeowners
                  that have access to the grid, off-grid solar systems are
                  usually out of question. Here’s why. To ensure access to
                  electricity at all times, off-grid solar systems require
                  high-capacity battery storage and a backup generator. On top
                  of this, a battery bank typically needs to be replaced after
                  10 years. Batteries are complicated, expensive, and decrease
                  overall system efficiency
                </Typography>
                <Typography
                  style={{
                    marginTop: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Benefits
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <li>
                    Can be installed where there is no access to the utility
                    grid.
                  </li>
                  <br />
                  <li>Become energy self-sufficient.</li>
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={images1}
                  alt="Left Side Image"
                  style={{
                    width: isSmallScreen ? "350px" : "600px",
                    height: isSmallScreen ? "150px" : "300px",
                    marginLeft: isSmallScreen ? "5px" : "20px",
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
      </Grid>
      <Grid style={{ padding: "10px" }}>
        <Typography
          variant="h4"
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Hybrid Solar System
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          style={{ marginTop: "30px" }}
        >
          <Grid item xs={12} md={6}>
            <img
              src={images2}
              alt="Left Side Image"
              style={{
                width: isSmallScreen ? "350px" : "600px",
                height: isSmallScreen ? "150px" : "300px",
                marginLeft: isSmallScreen ? "5px" : "20px",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
              Hybrid solar systems combine the best of grid-tied and off-grid
              solar systems. These systems can either be described as off-grid
              solar with utility backup power, or grid-tied solar with extra
              battery storage.
            </Typography>
            <Typography
              style={{
                marginTop: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Benefits
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              <li>Less expensive than off-grid solar systems.</li>
              <br />
              <li>Flexibility and Sustainability.</li>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SolarRoof;
