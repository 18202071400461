
// import React from 'react';

// const BatteryTable = ({ batteryDetails, columnHeadings, subColumns }) => {
//   return (
//     <table border="1" style={{ width: '80%', borderCollapse: 'collapse', margin: '20px auto' }}>
//       <thead>
//         <tr>
//           {columnHeadings.map((heading, index) => (
//             <th key={index} colSpan={subColumns[heading] ? subColumns[heading].length : 1} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '6px' }}>
//               {heading}
//             </th>
//           ))}
//         </tr>
//         {columnHeadings.some((heading) => subColumns[heading]) && (
//           <tr>
//             {columnHeadings.map((heading) =>
//               subColumns[heading] ? (
//                 subColumns[heading]?.map((subColumn, index) => (
//                   <th key={index} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '6px' }}>{subColumn} </th>
//                 ))
//               ) : (
//                 <th key={heading} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '6px' }}>{''}</th>
//               )
//             )}
//           </tr>
//         )}
//       </thead>
//       <tbody>
//         {batteryDetails.map((battery, index) => (
//           <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' }}>
//             {columnHeadings.map((heading) =>
//               subColumns[heading] ? (
//                 subColumns[heading]?.map((subColumn, subIndex) => (
//                   <td key={subIndex} style={{ padding: '6px', textAlign: 'center', fontSize: '14px' }}>{battery[heading][subColumn]}</td>
//                 ))
//               ) : (
//                 <td key={heading} style={{ padding: '6px', textAlign: 'center', fontSize: '14px' }}>{battery[heading]}</td>
//               )
//             )}
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };

// export default BatteryTable;
import React from 'react';

const BatteryTable = ({ batteryDetails, columnHeadings, subColumns }) => {
  return (
    <div style={{ overflowX: 'auto' }}> {/* Apply overflow scrolling for horizontal direction */}
      <table border="1" style={{ width: '100%', borderCollapse: 'collapse', margin: '20px auto' }}>
        <thead>
          <tr>
            {columnHeadings.map((heading, index) => (
              <th key={index} colSpan={subColumns[heading] ? subColumns[heading].length : 1} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '6px' }}>
                {heading}
              </th>
            ))}
          </tr>
          {columnHeadings.some((heading) => subColumns[heading]) && (
            <tr>
              {columnHeadings.map((heading) =>
                subColumns[heading] ? (
                  subColumns[heading]?.map((subColumn, index) => (
                    <th key={index} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '6px' }}>{subColumn} </th>
                  ))
                ) : (
                  <th key={heading} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '6px' }}>{''}</th>
                )
              )}
            </tr>
          )}
        </thead>
        <tbody>
          {batteryDetails.map((battery, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' }}>
              {columnHeadings.map((heading) =>
                subColumns[heading] ? (
                  subColumns[heading]?.map((subColumn, subIndex) => (
                    <td key={subIndex} style={{ padding: '6px', textAlign: 'center', fontSize: '14px' }}>{battery[heading][subColumn]}</td>
                  ))
                ) : (
                  <td key={heading} style={{ padding: '6px', textAlign: 'center', fontSize: '14px' }}>{battery[heading]}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BatteryTable;
