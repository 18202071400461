import React from "react";
import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import aboutUsImage from "./elevator.jpg";
import AboutInfo from "./AboutInfo";
import VisionMissionValuesSection from "./vision";

function AboutusPage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      style={{ overflow: "hidden" }}
    >
      <Grid item xs={12}>
        <img
          src={aboutUsImage}
          alt="About Us Image"
          style={{ width: "100%",height: "600px" }}
        />
      </Grid>
      <AboutInfo />
      <VisionMissionValuesSection />
    </Grid>
  );
}

export default AboutusPage;
