import React, { useState, useEffect } from "react";
import { Divider, Typography, Grid, useMediaQuery } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const carouselItems = [
  {
    imageUrl: require("./image.png"),
    heading: "Lift UPS",
    // content: [
    //   "Technical Specification for the Lift or Off-line UPS (Range 6kva to 120 kva)",
    //   "Technology: IGBT BASED",
    //   "Double conversion isolation Transformer...",
    // ],

    // component: require("./battery.jpg"),
  },
  {
    imageUrl: require("./homeUPS.jpg"),
    heading: "Home UPS",
    // content: [
    //   "Technical Specification for True Online UPS (Range 3kva to 150kva)",
    //   "Technology: IGBT BASED",
    //   "Double conversion Isolation Transformer...",
    // ],

    // component: require("./battery.jpg"),
  },
  {
    imageUrl: require("./batteries.jpg"),
    heading: "BATTERIES",
    // content: [
    //   "PRIME make Tubular Plate Range of Industrial Batteries and",
    //   "Solar Batteries are designed and",
    //   "manufactured for better performance and long....",
    // ],

    // component: require("./battery.jpg"),
  },
  {
    imageUrl: require("./image.png"),
    heading: "SERVICES",
    // content: [
    //   "Elites Power Solutions is committed to provide",
    //   "unparalled quality service",
    //   "during its entire life cycle of the products.",
    // ],

    // component: require("./battery.jpg"),
  },
];

const CarosuelPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width: 600px)"); // Adjust the width as needed

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
  //   }, 4000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [carouselItems.length]);

  const headingToLinkMap = {
    "Lift UPS": "/products/lift",
    "Home UPS": "/products/onlineUps",
    SERVICES: "/products/sinewaveUps",
    BATTERIES: "/products/battery",
  };

  // const handleReadMoreClick = () => {
  //   const currentHeading = carouselItems[currentIndex].heading;
  //   const linkPath = headingToLinkMap[currentHeading];

  //   console.log("Read More clicked for slide:", currentIndex);
  //   console.log(
  //     "Read More content:",
  //     carouselItems[currentIndex].readMoreContent
  //   );

  //   if (linkPath) {
  // Navigate to the desired link if it exists in the mapping
  //     window.location.href = linkPath;
  //   } else {
  //     console.warn(`No link found for heading: ${currentHeading}`);
  //   }
  // };

  // const handleReadMoreClick = () => {
  //   console.log('Read More clicked for slide:', currentIndex);
  //   console.log('Read More content:', carouselItems[currentIndex].readMoreContent);
  //   window.alert('This is a warning message!');
  // };

  return (
    <Grid>
      <Grid style={{ display: "flex", justifyContent: "center" }}>
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          selectedItem={currentIndex}
          autoPlay={true}
          interval={5000}
          width={"100%"}
        >
          {carouselItems.map((carouselItem, index) => (
            <Grid
              key={index}
              style={{
                position: "relative",
              }}
            >
              <img
                src={carouselItem.imageUrl}
                alt={carouselItem.heading}
                style={{
                  objectFit: isMobile ? "contain" : "cover",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  height: isMobile ? "300px" : "700px",
                }}
              />
              <div
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  background:
                    "linear-gradient(rgba(0,0,0,0,1)0%,rgba(119,76,0,1)100%)",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0, // Display heading at the top
                    left: 0,
                    width: "100%",
                    textAlign: "left",
                    color: "white",
                    zIndex: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                    background:
                      "linear-gradient(rgba(0,0,0,0,1)0%,rgba(119,76,0,1)100%)",
                    padding: "20px", // Add padding for better visibility
                  }}
                >
                  <Typography
                    variant="h2"
                    style={{
                      fontFamily: "Caroline",
                      color: "white",
                      fontWeight: "lighter",
                      fontSize: isMobile ? "2rem" : "4rem",
                      marginLeft: "10px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {carouselItem.heading}
                  </Typography>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 0, // Display content at the bottom
                    left: 0,
                    width: "100%",
                    textAlign: "left",
                    color: "white",
                    zIndex: 1,
                    padding: "20px", // Add padding for better visibility
                  }}
                >
                  <Typography
                    // variant="body1"
                    style={{
                      fontSize: isMobile ? "0.5rem" : "1.5rem",
                      marginBottom: "10px",
                    }}
                  >
                    {Array.isArray(carouselItem.content)
                      ? carouselItem.content.map((line, index) => (
                          <div key={index} style={{ marginBottom: "5px" }}>
                            {line}
                          </div>
                        ))
                      : carouselItem.content}
                  </Typography>
                  {/* <Grid
                    style={{ cursor: "pointer" }}
                    onClick={handleReadMoreClick}
                    content={carouselItem.readMoreContent}
                  >
                    <Typography style={{ fontSize: isMobile ? "8px" : "15px" }}>
                      Read More....
                    </Typography>
                  </Grid> */}
                </div>
              </div>
            </Grid>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default CarosuelPage;
