import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import emailjs from "emailjs-com";
import ServiceSelector from "./ServiceSelector";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
const ServicesForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    telephone: "",
    city: "",
    pincode: "",
  });
  const [service, setService] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const serviceList = [
    "Lift or Offline UPS",
    "True Online UPS",
    "Sinewave Solar Inverter / UPS",
    "Batteries",
    "Stabilizers",
    "Solar Roof Top System",
  ];

  const serviceData = {
    ...formData,
    service: serviceList[service - 1],
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(
        "service_fq9hju9",
        "template_yg20i4x",
        serviceData,
        "g-RFh1m7d2Pj18TiQ"
      );

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error sending email. Please try again.");
    }
    // Add your logic to handle form submission (e.g., send data to the server)
    console.log("Form submitted:", serviceData);
  };

  return (
    <Card elevation={4} style={{ marginTop: "20px" }}>
      <Typography
        variant="h5"
        gutterBottom
        style={{ textAlign: "center", marginTop: "10px" }}
      >
        Send a Query
      </Typography>
      <CardContent>
        <ValidatorForm onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                // validators={['required', 'minStringLength: 4', 'maxStringLength: 20']}
                // errorMessages={['Name is required', 'Name must be at least 4 characters', 'Name must be at most 20 characters']}
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="Telephone"
                type="tel"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required", "isNumber", "matchRegexp:^\\d{10}$"]}
                errorMessages={[
                  "This field is required",
                  "Must be a number",
                  "Phone number must be 10 digits",
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="City"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="Pincode"
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required"]}
                errorMessages={["Pincode is required"]}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="service-label">Service</InputLabel>
                <Select
                  labelId="service-label"
                  label="Service"
                  value={service}
                  onChange={(e) => {
                    setService(e.target.value);
                  }}
                  fullWidth
                  required
                >
                  <MenuItem value={1}>Lift or Offline UPS</MenuItem>
                  <MenuItem value={2}>True Online UPS</MenuItem>
                  <MenuItem value={3}>Sinewave Solar Inverter / UPS</MenuItem>
                  <MenuItem value={4}>Batteries</MenuItem>
                  <MenuItem value={5}>Stabilizers</MenuItem>
                  <MenuItem value={6}>Solar Roof Top System</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="Description"
                type="text"
                name="description"
                multiline
                rows={3}
                value={formData.description}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  marginTop: 20,
                  color: "white ",
                  backgroundColor: "#1c548d",
                  width: "100%",
                  border: "1px solid #1c548d ",
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </CardContent>
    </Card>
  );
};

export default ServicesForm;
