import React from "react";
import {
  Card,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Vision from "./vision.jpg";
import Mission from "./mission.jpeg";
import Value from "./values.png";

const values = [
  {
    title: "Quality",
    description:
      "Quality, consistency, and durability are the building blocks on which all our products and services are built. Our team relentlessly pursues this 24*7.",
  },
  {
    title: "Commitment",
    description:
      "We are committed to making our customer's businesses more efficient through our products and services.",
  },
  {
    title: "Innovation",
    description:
      "Unleashing creativity to engineer cutting-edge solutions that redefine industry standards.",
  },
  {
    title: "Integrity",
    description:
      "Commitment to transparency, honesty, and ethical practices in every aspect of our business.",
  },
  {
    title: "Collaboration",
    description:
      "Fostering a culture of teamwork and partnership, where collective efforts drive unparalleled results.",
  },
  {
    title: "Excellence",
    description:
      "Relentless pursuit of excellence in delivering top-tier products and services to our clients.",
  },
  {
    title: "Technology",
    description:
      "We seek innovation in everything we do by adopting the latest technology to meet the ever-changing needs of our customers.",
  },
  {
    title: "Enrichment",
    description:
      " We believe in adding value to our customers through our products and services, by practicing transparency and fairness in our business.",
  },

  {
    title: "Empowerment",
    description:
      "Investing in our team's growth, enabling them to achieve their full potential and contribute meaningfully.",
  },
  {
    title: "Continuous Improvement",
    description:
      "Learning and adoption are our way of life. We like to be better than yesterday, driving us to keep ourselves open to change.",
  },
  {
    title: "Sustainability",
    description:
      "Championing environmentally conscious practices to create a positive impact on our planet.",
  },
];

const VisionMissionValuesSection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const cardh1 = {
    color: "#333",
    width: isSmallScreen ? "250px" : "900px",
    textAlign: "center",
  };
  const cardul = {
    listStyle: "none",
    padding: "10px",
    textAlign: "left",
    spacing: "3",
    marginBottom: "10px",
  };
  const card = {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: isSmallScreen ? "10px" : "20px",
    textAlign: "center",
  };

  const cardContent = {
    textAlign: "center",
  };

  const cardh2 = {
    color: "#333",
    marginLeft: "30px",
    marginTop: "-10px",
  };

  const cardp = {
    fontSize: "18px",
    marginBottom: isSmallScreen ? "10px" : "",
  };

  const splitValues = (values) => {
    const half = Math.ceil(values.length / 2);
    const firstHalf = values.slice(0, half);
    const secondHalf = values.slice(half);

    return { firstHalf, secondHalf };
  };
  const { firstHalf, secondHalf } = splitValues(values);

  return (
    <Grid
      container
      spacing={1}
      style={{ justifyContent: "center", padding: "10px " }}
    >
      <Grid item xs={12} sm={6} md={6} lg={6}>
        {/* Vision Card */}
        <Card
          style={{
            ...card,
            height: "450px",
            marginBottom: "10px",
          }}
        >
          <CardContent>
            <h1 style={cardh2}>Vision</h1>
            <img
              src={Vision}
              alt="Vision"
              style={{ maxWidth: "100%", width: "270px", marginBottom: "10px" }}
            />

            <p style={cardp}>"Shed the Darkness, Spread the Lightness"</p>
            <p style={cardp}>
              To be the most preferred brand with our customers in the markets
              we serve.
            </p>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        {/* Mission Card */}
        <Card
          style={{
            ...card,
            height: isSmallScreen ? "600px" : "450px",
            marginBottom: "10px",
          }}
        >
          <CardContent style={cardContent}>
            <h1 style={cardh2}>Mission</h1>
            <img
              src={Mission}
              alt="Mission"
              style={{ maxWidth: "100%", marginBottom: "5px" }}
            />
            <p style={cardp}>
              "Pioneering Sustainable Solutions for a Connected Tomorrow."
            </p>
            <p style={cardp}>
              By passionately pursuing our business activities keeping a
              customer-centric approach and technology as key drivers and
              adopting continuous improvement as a means to achieve customer
              value enrichment.
            </p>
          </CardContent>
        </Card>
      </Grid>

      <Grid style={{ alignSelf: "center" }} item xs={12} sm={6} lg={9} md={9}>
        <Card
          style={{
            ...card,
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <CardContent style={cardContent}>
            <h1 style={cardh2}>Values</h1>
            <img
              src={Value}
              alt="Value"
              style={{
                maxWidth: "100%",
                width: isSmallScreen ? "200px" : "400px",
                marginBottom: "5px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
              }}
            >
              <ul
                style={{
                  ...cardul,
                  flex: 1,
                  marginRight: isSmallScreen ? "0" : "10px",
                  textAlign: "justify",
                }}
              >
                {firstHalf.map((value, index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <p style={cardp}>
                      <strong>{value.title}:</strong> {value.description}
                    </p>
                  </li>
                ))}
              </ul>
              <ul style={{ ...cardul, flex: 1, textAlign: "justify" }}>
                {secondHalf.map((value, index) => (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <p style={cardp}>
                      <strong>{value.title}:</strong> {value.description}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default VisionMissionValuesSection;
