// import React from 'react';
// import { useTheme, useMediaQuery, Grid } from '@mui/material';

// const DataTable = ({data}) => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   // Check if the data is present
//   if (!data || !data.columnHeadings || !data.tableContent) {
//     return <p>Error: Invalid data structure</p>;
//   }

//   const { columnHeadings, tableContent, tableTitle } = data;

//   const renderTableHeader = () => (
//     <thead>
//       <tr>
//         {columnHeadings.map((heading, index) => (
//           <th key={index} style={{ backgroundColor: '#4a76b2', color: 'white', padding: '8px', fontSize: isSmallScreen ? '12px' : 'inherit',border: '2px solid black' }}>{heading}</th>
//         ))}
//       </tr>
//     </thead>
//   );

//   const renderTableBody = () => (
//     <tbody>
//       {tableContent.map((row, rowIndex) => (
//         <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f5f5f5' }}>
//           {columnHeadings.map((column, colIndex) => (
//             <td key={colIndex} style={{ fontSize: isSmallScreen ? '12px' : 'inherit',fontWeight: colIndex === 0 ? 'bold' : 'normal',border: '2px solid black'  }}>
//               {Array.isArray(row[column]) ? row[column].join(', ') : row[column]}
//             </td>
//           ))}
//         </tr>
//       ))}
//     </tbody>
//   );

//   return (
//     <div>
//       <div style={{textAlign:'center'}}>
//         <h2 style={{ fontSize: isSmallScreen ? '16px' : 'inherit' }}>{tableTitle}</h2>
//       </div>
//       <Grid container spacing={1}>
//         <Grid item xs={12} sm={5} md={8} lg={8} style={{ margin: 'auto', width: isSmallScreen ? '90%' : '100%' }}>
//           <table border="1" style={{ fontSize: isSmallScreen ? '12px' : 'inherit',borderCollapse: 'collapse'  }}>
//             {!isSmallScreen && renderTableHeader()}
//             {renderTableBody()}
//           </table>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default DataTable;
import React from "react";
import { useTheme, useMediaQuery, Grid } from "@mui/material";

const DataTable = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Check if the data is present
  if (!data || !data.columnHeadings || !data.tableContent) {
    return <p>Error: Invalid data structure</p>;
  }

  const { columnHeadings, tableContent, tableTitle } = data;

  const renderTableHeader = () => (
    <thead>
      <tr>
        {columnHeadings.map((heading, index) => (
          <th
            key={index}
            style={{
              textAlign: "center",
              backgroundColor: "#4a76b2",
              color: "white",
              padding: "8px",
              fontSize: isSmallScreen ? "12px" : "inherit",
              border: "2px solid black",
            }}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      {tableContent.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          style={{
            textJustify: "start",
            backgroundColor: rowIndex % 2 === 0 ? "white" : "#f5f5f5",
          }}
        >
          {columnHeadings.map((column, colIndex) => (
            <td
              key={colIndex}
              style={{
                verticalAlign: "top",
                fontSize: isSmallScreen ? "12px" : "inherit",
                fontWeight: colIndex === 0 ? "bold" : "normal",
                border: "2px solid black",
              }}
            >
              {Array.isArray(row[column])
                ? row[column].join(", ")
                : row[column]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontSize: isSmallScreen ? "16px" : "inherit" }}>
          {tableTitle}
        </h2>
      </div>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={5}
          md={8}
          lg={8}
          style={{ margin: "auto", width: isSmallScreen ? "90%" : "100%" }}
        >
          <table
            style={{
              textAlign: "start",
              fontSize: isSmallScreen ? "12px" : "inherit",
              borderCollapse: "collapse",
              border: "2px solid black",
            }}
          >
            {renderTableHeader()}
            {renderTableBody()}
          </table>
        </Grid>
      </Grid>
    </div>
  );
};

export default DataTable;
