import React from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CategoryIcon from "@mui/icons-material/Category";
import StorageIcon from "@mui/icons-material/Storage";

const sellingPointsData = [
  {
    icon: <CheckCircleIcon fontSize="large" />,
    title: "Assured Quality",
    description: "Latest products and 100% genuine",
  },
  {
    icon: <MonetizationOnIcon fontSize="large" />,
    title: "Best Prices Guarantee",
    description: "Won’t find a better price anywhere    ",
  },
  {
    icon: <CategoryIcon fontSize="large" />,
    title: "Wide Product Range",
    description: "All Leading brand's batteries are available",
  },
  {
    icon: <StorageIcon fontSize="large" />,
    title: "Large Storage Capacity",
    description: "We are equiped to supply large volume/quantity",
  },
];

const SellingPointsSection = () => {
  return (
    <Container style={{ marginTop: "30px", marginBottom: "30px" }}>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Quality Assurance and Genuine Products
      </Typography>
      <Grid container spacing={3}>
        {sellingPointsData.map((point, index) => (
          <Grid item key={index} xs={12} md={6} lg={3}>
            <Box
              sx={{
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                textAlign: "center",
                p: { xs: 0.5, sm: 2 },
                color: "darkblue",
                maxWidth: { xs: "90%", sm: "50%", md: "100%" },
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                marginLeft: { xs: 1, sm: 0 },
              }}
            >
              {point.icon}
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                {point.title}
              </Typography>
              <Typography variant="body2">{point.description}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SellingPointsSection;
