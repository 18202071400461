// import { Grid, Typography, useMediaQuery } from "@mui/material";
// import React from "react";
// import pdf1 from "./BrochureLift.pdf";
// import pdf from "./Elites UPS Brochure.pdf"
// function Catelog() {
//   const isMobile = useMediaQuery("(max-width: 600px)");
//   const iframeWidth = isMobile ? "100%" : "60%";
//   const iframeHeight = isMobile ? "800px" : "1000px";
//   return (
//     <div>
//       <Grid
//         item
//         style={{
//           textAlign: "center",
//           padding: "20px",
//           marginBottom: "10px",
//           marginTop: "10px",
//         }}
//       >
//         <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
//           UPS Brochure
//         </Typography>
//       </Grid>
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <iframe
//           src={pdf}
//           width={iframeWidth}
//           height={iframeHeight}
//           style={{ border: "none" }}
//         />
//       </div>
//       <Grid
//         item
//         style={{
//           textAlign: "center",
//           padding: "20px",
//           marginBottom: "10px",
//           marginTop: "10px",
//         }}
//       >
//         <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
//           Lifts Brochure
//         </Typography>
//       </Grid>
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <iframe
//           src={pdf1}
//           width={iframeWidth}
//           height={iframeHeight}
//           style={{ border: "none" }}
//         />
//       </div>
//     </div>
//   );
// }

// export default Catelog;


// import { Grid, Typography, useMediaQuery } from "@mui/material";
// import React from "react";
// import pdf1 from "./BrochureLift.pdf";
// import pdf from "./Elites UPS Brochure.pdf";

// function Catelog() {
//   const isMobile = useMediaQuery("(max-width: 600px)");
//   const iframeWidth = "100%"; // Set width to 100% for both mobile and larger screens
//   const iframeHeight = isMobile ? "800px" : "1000px";
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "center",
//     padding: "20px", // Adjust padding to make it consistent
//     marginBottom: "10px",
//     marginTop: "10px",
//   };

//   return (
//     <div>
//       <Grid item style={{ textAlign: "center", ...containerStyle }}>
//         <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
//           UPS Brochure
//         </Typography>
//       </Grid>
//       <div style={containerStyle}>
//         <iframe
//           src={pdf}
//           width={iframeWidth}
//           height={iframeHeight}
//           style={{ border: "none" }}
//           scrolling="yes" // Enable scrolling
//         />
//       </div>
//       <Grid item style={{ textAlign: "center", ...containerStyle }}>
//         <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
//           Lifts Brochure
//         </Typography>
//       </Grid>
//       <div style={containerStyle}>
//         <iframe
//           src={pdf1}
//           width={iframeWidth}
//           height={iframeHeight}
//           style={{ border: "none" }}
//           scrolling="yes" // Enable scrolling
//         />
//       </div>
//     </div>
//   );
// }
// export default Catelog;

import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import pdf1 from './ LiftsBrochure.pdf' 
import pdf from "./UPSBrochure.pdf";

function Catelog() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const iframeWidth = "80%"; // Set width to 100% for both mobile and larger screens
  const iframeHeight = isMobile ? "800px" : "1000px";
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    padding: "20px", // Adjust padding to make it consistent
    marginBottom: "10px",
    marginTop: "10px",
  };

  const openPDF = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <div>
      <Grid item style={{ textAlign: "center", ...containerStyle }}>
        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          UPS Brochure
        </Typography>
      </Grid>
      <div style={containerStyle}>
        {isMobile ? (
          <button onClick={() => openPDF(pdf)}>Click here to open PDF</button>
        ) : (
          <iframe
            src={pdf}
            width={iframeWidth}
            height={iframeHeight}
            style={{ border: "none" }}
          />
        )}
      </div>
      <Grid item style={{ textAlign: "center", ...containerStyle }}>
        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Lifts Brochure
        </Typography>
      </Grid>
      <div style={containerStyle}>
        {isMobile ? (
          <button onClick={() => openPDF(pdf1)}>Click here to open PDF</button>
        ) : (
          <iframe
            src={pdf1}
            width={iframeWidth}
            height={iframeHeight}
            style={{ border: "none" }}
          />
        )}
      </div>
    </div>
  );
}

export default Catelog;
