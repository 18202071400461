
import React from 'react';

const TubularContent = ({ product }) => {

  console.log('product',product)
 
  const styles = {
    productContainer: {
      margin: '20px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
    },
    productDescription: {
      fontStyle: 'italic',
      color: '#555',
    },
    productSection: {
      marginTop: '20px',
    },
    listItem: {
      marginBottom: '8px',
    },
  };

  return (
    <div style={styles.productContainer}>
      <h2>{product.name}</h2>
      <p style={styles.productDescription}>{product?.description}</p>

      <div style={styles.productSection}>
        <h3>Features:</h3>
        <ul>
          {product?.features?.map((feature, index) => (
            <li key={index} style={styles.listItem}>{feature}</li>
          ))}
        </ul>
      </div>

     {product?.applications ?<div style={styles.productSection}>
        <h3>Applications:</h3>
        <ul>
          {product?.applications?.map((application, index) => (
            <li key={index} style={styles.listItem}>{application}</li>
          ))}
        </ul>
      </div> : null } 
    </div>
  );
};

export default TubularContent;
