import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ThumbnailGallery = ({ imageData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const galleryImages = imageData?.map((image) => ({
    ...image,
    renderItem: (item) => (
      <div
        style={{
          border: "2px solid #ccc",
          padding: "10px",
          marginLeft: "10px",
          borderRadius: "8px",
        }}
      >
        <img
          src={item.original}
          alt={item.description} 
          style={{
            maxWidth: "100%",
            height: "auto",
            maxHeight: isSmallScreen ? "400px" : "400px",
          }}
      
        />
      </div>
    ),
  }));
  const galleryOptions = {
    showNav: true,

    // showThumbnails: false,
    showPlayButton: false,
  };

  return <Gallery items={galleryImages} {...galleryOptions} />;
};

export default ThumbnailGallery;
