import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const TabComponent = ({ tabsData }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      style={{
        marginTop: "40px",
        overflowX: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      <div>
        <Box
          sx={{
            borderColor: "divider",

            scrollbarWidth: "auto",
            width: isSmallScreen ? "100%" : "100%",
            border: "1px solid",
          }}
        >
          <Tabs
            value={activeTab}
            orientation="vertical"
            onChange={handleTabChange}
            // variant={isSmallScreen ? "scrollable" : "standard"}
            // scrollButtons={isSmallScreen ? "auto" : "off"}
          >
            {tabsData.tabs.map((tab, index) => (
              <Tab
                key={tab.id}
                label={<span>{tab.label}</span>}
                sx={{
                  fontSize: isSmallScreen ? "12px" : "16px",
                  fontWeight: "bold",
                  borderBottom: "1px solid",
                  backgroundColor: activeTab === index ? "#dcdcdc" : "inherit",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </div>
      <div>
        <Box
          sx={{
            // height: "350px",

            scrollbarWidth: "auto",
            width: isSmallScreen ? "100%" : "500px",
            fontSize: isSmallScreen ? "14px" : "16px",
            // marginLeft: "50px",
            "& > div": {
              marginBottom: "10px",
              border: "1px solid",
            },
          }}
        >
          {tabsData.tabs.map(
            (tab, index) =>
              activeTab === index && (
                <div key={tab.id}>
                  <h3>{tab.label}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tab.content.replace(/\n/g, "<br /> • "),
                    }}
                    style={{ marginLeft: "40px" }}
                  />
                </div>
              )
          )}
        </Box>
      </div>
    </Grid>
  );
};

export default TabComponent;
