// import React from "react";

// const Table = ({ columnHeadings, data }) => {
//   return (
//     <table border="1">
//       <thead>
//         <tr>
//           {columnHeadings.map((heading, index) => (
//             <th key={index}>{heading} </th>
//           ))}
//         </tr>
//       </thead>
//       <tbody>
//         {data.map((row, rowIndex) => (
//           <tr key={rowIndex}>
//             {columnHeadings.map((heading, colIndex) => (
//               <td key={colIndex}>{row[heading]}</td>
//             ))}
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };

// export default Table;

// import React from "react";

// const Table = ({ columnHeadings, data }) => {
//   return (
//     <div style={{ textAlign: 'center' }}>
//       <table border="1" style={{ width: '80%', margin: 'auto', borderCollapse: 'collapse' }}>
//         <thead>
//           <tr>
//             {columnHeadings.map((heading, index) => (
//               <th key={index} style={{ backgroundColor: '#4a76b2', padding: '8px' }}>
//                 {heading}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((row, rowIndex) => (
//             <tr key={rowIndex} style={{backgroundColor: rowIndex%2 === 0? 'white' : '#f5f5f5'}}>
//               {columnHeadings.map((heading, colIndex) => (
//                 <td key={colIndex} style={{ padding: '8px', textAlign: 'center',  }}>
//                   {row[heading]}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default Table;
import React from "react";

const Table = ({ columnHeadings, data }) => {
  return (
    <div style={{ overflowX: "auto", maxWidth: "100%" }}>
      <table
        border="1"
        style={{
          width: "80%",
          margin: "auto",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            {columnHeadings.map((heading, index) => (
              <th
                key={index}
                style={{
                  backgroundColor: "#4a76b2",
                  padding: "8px",
                }}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{
                backgroundColor: rowIndex % 2 === 0 ? "white" : "#f5f5f5",
              }}
            >
              {columnHeadings.map((heading, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {row[heading]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
