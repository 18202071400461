import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import logo from "../assets/elite.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const buttonStyles = {
  color: "#1e558d",
  fontSize: "18px",
  fontFamily: "Georgia, serif",
  "&:hover": {
    color: "#69b6b1",
    backgroundColor: "transparent",
  },
};

const contactButtonStyles = {
  ...buttonStyles,
  marginRight: "40px", // Add margin to the right for Contact Us button
};

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function to handle opening the dropdown menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the dropdown menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }}>
      <CssBaseline />
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Link
          style={{
            cursor: "pointer",
          }}
          to="/"
        >
          <img src={logo} alt="Elite Logo" style={{ height: "50px" }} />
        </Link>

        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div>
            <Button component={Link} to="/" color="inherit" sx={buttonStyles}>
              Home
            </Button>
            <Button
              component={Link}
              to="/about"
              color="inherit"
              sx={buttonStyles}
            >
              About Us
            </Button>

            {/* Dropdown Button */}
            <Button
              color="inherit"
              onMouseEnter={handleMenuOpen}
              // onMouseOut={handleMenuClose}
              // onClick={handleMenuOpen}
              sx={buttonStyles}
              endIcon={<ExpandMoreIcon />}
            >
              Products
            </Button>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/lift"
              >
                Lift UPS
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/onlineUps"
              >
                Home UPS
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/lift1"
              >
                Lifts
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/SolarRoof"
              >
                Solar Roof Top System
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/stabilizers"
              >
                Stabilizers
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/battery"
              >
                Batteries
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/products/sinewaveUps"
              >
                Sinewave Solar Inverter / UPS
              </MenuItem>
            </Menu>

            {/* Other buttons */}
            <Button
              component={Link}
              to="/catelog"
              color="inherit"
              sx={buttonStyles}
            >
              Catalogue
            </Button>
            <Button
              component={Link}
              to="/gallery"
              color="inherit"
              sx={buttonStyles}
            >
              Gallery
            </Button>
            <Button
              component={Link}
              to="/services"
              color="inherit"
              sx={buttonStyles}
            >
              Services
            </Button>

            <Button
              component={Link}
              to="/contact"
              color="inherit"
              sx={buttonStyles}
            >
              Contact Us
            </Button>
            <Button
              component={Link}
              to="/customers"
              color="inherit"
              sx={contactButtonStyles}
            >
              Customers
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
