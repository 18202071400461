// import React, { useEffect } from 'react';
// import { Container, Typography, Grid } from '@mui/material';
// import img1 from './img1.jpeg';

// const SineWaveUPS = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <Grid
//       style={{
//         height: '100vh',
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         alignItems: 'center',
//         color: 'white',
//         textAlign: 'center',
//         backgroundImage: `url(${img1})`,
//       }}
//     >
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Typography style={{fontFamily: 'Georgia',fontWeight: 'bold',fontSize:'35px'}}>
//             Technical Specification for Sine Wave Solar Inverter / UPS (Range - 400 VA Onwards)
//           </Typography>
//         </Grid>
//         <Grid item xs={12}>
//           <Typography style={{fontFamily: 'Georgia',fontWeight: 'bold',fontSize:'25px'}}>
//             Technology IGBT BASED Double conversion Isolation Transformer Technology – Latest Microcontroller based PWM Generation.
//           </Typography>
//         </Grid>
//         <Grid item xs={12} >
//           <Typography  style={{fontFamily: 'Georgia',fontWeight: 'bold',fontSize:'22px' }}>Salient Features of Sine Wave Solar Inverter / UPS</Typography>
//           <ul style={{ listStyleType: 'none', padding: 0 ,fontSize:'20px'}}>
//             <li>True sine wave output with High-Frequency digital PWM (Pulse Width Modulation) controller design.</li>
//             <li>Output short circuit protection.</li>
//             <li>Inbuilt Manual Bypass switch.</li>
//             <li>Battery Reverse protection.</li>
//             <li>Extended Battery backup.</li>
//             <li>Compact design for Space efficiency.</li>
//           </ul>
//         </Grid>
//         <Grid item xs={12}>
//           <Typography style={{fontFamily: 'Georgia',fontSize:'20px',marginBottom:'0px'}}>
//             *Note: R&D is a continuous process, Specifications are subject to change without prior notice.
//           </Typography>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// };

// export default SineWaveUPS;
import React, { useEffect } from 'react';
import { Container, Typography, Grid,useMediaQuery,useTheme } from '@mui/material';
import img1 from './img1.jpeg';

const SineWaveUPS = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      style={{
        height:isSmallScreen? '80vh': '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        backgroundImage: `url(${img1})`,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Typography style={{fontFamily: 'Georgia', fontWeight: 'bold', fontSize:isSmallScreen? '18px' : '35px' }}>
            Technical Specification for Sine Wave Solar Inverter / UPS (Range - 400 VA Onwards)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontFamily: 'Georgia', fontWeight: 'bold', fontSize:isSmallScreen? '16px': '25px' }}>
            Technology IGBT BASED Double conversion Isolation Transformer Technology – Latest Microcontroller based PWM Generation.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontFamily: 'Georgia', fontWeight: 'bold', fontSize:isSmallScreen? '14px' :'22px' }}>Salient Features of Sine Wave Solar Inverter / UPS</Typography>
          <ul style={{ listStyleType: 'none', padding: 0, fontSize:isSmallScreen? '12px': '20px', background:isSmallScreen? 'rgba(0,0,0,0.5)': '', borderRadius: '10px',  maxWidth:isSmallScreen? '500px': '', }}>
            <li>True sine wave output with High-Frequency digital PWM (Pulse Width Modulation) controller design.</li>
            <li>Output short circuit protection.</li>
            <li>Inbuilt Manual Bypass switch.</li>
            <li>Battery Reverse protection.</li>
            <li>Extended Battery backup.</li>
            <li>Compact design for Space efficiency.</li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontFamily: 'Georgia', fontSize: '20px', marginBottom: '0px' }}>
            *Note: R&D is a continuous process, Specifications are subject to change without prior notice.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SineWaveUPS;
