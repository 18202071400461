
import React, { useEffect } from "react";
import Banner from "./Banner";
import ThumbnailGallery from "./ImageViewer";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import data1 from "./UpsContent.json"
import imageData1 from "./UpsContent.json"
import LiftDataDisplay from "./lifts1Content";
import jsonData from "./UpsContent.json"

const Lift = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const{companyInfo}=jsonData
  return (
    <Grid style={{marginBottom:'50px'}}>
      <Banner data={data1} />
      <Grid container spacing={1}>

<Grid  style={{display:"flex",flexDirection:isMobile? 'column':"row",marginTop:'20px'}}>
        <Grid item xs={12} >
          <ThumbnailGallery imageData={imageData1.images} />
        </Grid>
        <Grid
          item
          xs={12}    
          style={{ marginTop: "-10px", marginLeft: "20px" }}
        >
        <LiftDataDisplay jsonData={jsonData} />
        </Grid>
        </Grid>
      </Grid>
      <Grid>
       <Grid item xs={12} sm={6} md={4} style={{padding:'20px'}}>
        <h3>Features:</h3>
        <p><strong>Technology:</strong> {companyInfo.features?.technology}</p>
        <p><strong>Door:</strong> {companyInfo.features?.door}</p>
        <ul>
          {companyInfo.features?.safetyFeatures.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <p><strong>Elevator Controllers:</strong> {companyInfo.features?.elevatorControllers.technology}</p>
        <ul>
          {companyInfo.features?.elevatorControllers.features?.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </Grid> 
      
       <Grid item xs={12} sm={6} md={4}style={{padding:'20px'}}>
        <h3>New Technologies:</h3>
        <p><strong>IOT:</strong> {companyInfo.newTechnologies?.IOT?.description}</p>
      </Grid> 
       </Grid>
    </Grid>
  );
      <Grid
        alignItems="center"
        justify="center"
        style={{
          marginBottom: "10px",
        }}
      ></Grid>
    

};
export default Lift;
