import React, { useEffect, useState } from "react";
import Banner from "../products/lifts/Banner";
import ThumbnailGallery from "../products/lifts/ImageViewer";
import { Grid, Typography, useMediaQuery } from "@mui/material";
// import  data1 from "./galleryData.json"
import liftupsData from "./galleryData1.json";
import liftData from "./galleryData1.json";
import sinewaveData from "./galleryData1.json";
import batteriesData from "./galleryData1.json";
import stabilizerData from "./galleryData1.json";
import solarRoofData from "./galleryData1.json";
import { Hourglass } from "react-loader-spinner";
import v1 from "./elites1.mp4";
import v2 from "./e2.mp4";
import v3 from "./elites3.mp4";
import v4 from "./elites4.mp4";
import v6 from "./elites6.mp4";
import v7 from "./elites7.mp4";
import v8 from "./elites8.mp4";
import v10 from "./elites10.mp4";
import v12 from "./elites2.mp4";
import v5 from "./elites5.mp4";

const Gallery = () => {
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#97905E", "#000000"]}
          />
        </div>
      )}
      <Grid
        container
        spacing={5}
        style={{ padding: "10px" }}
        onLoad={handleImageLoad}
      >
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              padding: "10px",
            }}
          >
            Lift And Home UPS
          </Typography>
          <ThumbnailGallery imageData={liftupsData?.liftups} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              padding: "10px",
            }}
          >
            Lifts
          </Typography>
          <ThumbnailGallery imageData={liftData?.lift} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              padding: "10px",
            }}
          >
            Solar System{" "}
          </Typography>
          <ThumbnailGallery imageData={solarRoofData?.solarRoof} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              padding: "10px",
            }}
          >
            Stabilizer
          </Typography>
          <ThumbnailGallery imageData={stabilizerData?.stabilizer} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
              padding: "10px",
            }}
          >
            Batteries
          </Typography>
          <ThumbnailGallery imageData={batteriesData?.batteries} />
        </Grid>

        {/* <Grid item xs={12} md={6}>
        <Typography style={{fontWeight: 'bold',textAlign:'center',fontSize:'20px',padding:'10px'}}>Sinewave</Typography>
          <ThumbnailGallery imageData={sinewaveData?.sinewave} />
        </Grid> */}
      </Grid>
      <Typography
        style={{
          textAlign: "center",
          marginTop: "40px",
          margin: "8px",
          fontSize: "24px",
          fontWeight: "bold",
        }}
      >
        Video Section
      </Typography>
      <Grid
        container
        style={{
          marginTop: "20px",
          padding: "10px",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            just: "center",
            margin: "8px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Lifts
        </Typography>
        <Grid
          container
          style={{ display: "flex", flexDirection: "row", textAlign: "center" }}
        >
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v12} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v5} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v7} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v8} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
        <Typography
          style={{
            textAlign: "center",
            just: "center",
            margin: "8px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Solar System
        </Typography>
        <Grid
          container
          style={{ display: "flex", flexDirection: "row", textAlign: "center" }}
        >
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v10} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
        <Typography
          style={{
            textAlign: "center",
            just: "center",
            margin: "8px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          Lift and Home UPS
        </Typography>
        <Grid
          container
          style={{ display: "flex", flexDirection: "row", textAlign: "center" }}
        >
          <Grid item xs={12} md={6}>
            <video muted controls width={isMobile ? "350" : "400"} height="300">
              <source src={v6} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Gallery;
