import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const SingleCard = ({ backgroundColor, img, title, description }) => {
  const cardStyle = {
    width: 250,
    height: 400,
    position: "relative",
    scrollbarWidth: "thin",
    scrollbarColor: "#63B4AE white",
    overflowY: "auto",
    backgroundColor: backgroundColor || "red",
  };

  const imgStyle = {
    marginTop: 10,
    marginBottom: 10,
    color: "darkblue",
  };

  const titleStyle = {
    marginTop: 10,
    fontSize: 22,
    color: "white",
  };

  const descriptionStyle = {
    opacity: 1, // Always show the description
    color: "white",
    marginTop: 20,

    listStyleType: "disc",
  };

  return (
    <Card style={cardStyle}>
      <CardContent>
        <Typography variant="subtitle1" style={titleStyle}>
          {title}
        </Typography>

        <Typography variant="h5" gutterBottom style={imgStyle}>
          {img && <img src={img} alt={title} style={{ width: "100%" }} />}
        </Typography>

        <Typography variant="subtitle1" style={descriptionStyle}>
          <ul>
            {Array.isArray(description) ? (
              description.map((desc, index) => (
                <li key={index}>
                  <Typography variant="body2">{desc}</Typography>
                </li>
              ))
            ) : (
              <li>
                <Typography variant="body2">{description}</Typography>
              </li>
            )}
          </ul>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SingleCard;
