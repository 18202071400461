import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Hourglass } from "react-loader-spinner";
import ImageViewer from "react-simple-image-viewer";

// import  data1 from "./galleryData.json"
import galleryData from "./gallery/galleryData.json";
function Customers() {
  useEffect(() => {
    setLoading(false);
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
  const srcArray = galleryData.customers.map((item) => item.src);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#97905E", "#000000"]}
          />
        </div>
      )}
      <Grid
        container
        style={{
          marginTop: "30px",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Typography
          style={{ fontSize: "24px", margin: "18px", fontWeight: "bold" }}
        >
          Our Happy Customers!
        </Typography>
        <Grid>
          {galleryData.customers.map((image, index) => (
            <img
              key={index}
              src={image.src}
              onClick={() => openImageViewer(index)}
              width={"400px"}
              height={"400px"}
              style={{ objectFit: "contain", margin: "2px" }}
              alt=""
              onLoad={handleImageLoad}
            />
          ))}
        </Grid>
        {isViewerOpen && (
          <ImageViewer
            src={srcArray}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </Grid>
    </div>
  );
}

export default Customers;
