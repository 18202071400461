import React from "react";

const BatteryCont = ({ batteryCareTips }) => {
  if (!batteryCareTips) {
    return <div>No battery care tips available.</div>;
  }
  const {
    generalCare = [],
    initialChargingInstructions = [],
    caution = [],
    note = [],
  } = batteryCareTips;


  return (
    <div style={{marginLeft:'20px'}}>
      <h2>General Care</h2>
      <ul>
        {batteryCareTips.generalCare.map((tip, index) => (
          <li key={index}>{tip}</li>
        ))}
      </ul>

      <h2>Initial Charging Instructions</h2>
      <ul>
        {batteryCareTips.initialChargingInstructions.map((instruction, index) => (
          <li key={index}>{instruction}</li>
        ))}
      </ul>

      <h2>Caution</h2>
      <ul>
        {batteryCareTips.caution.map((caution, index) => (
          <li key={index}>{caution}</li>
        ))}
      </ul>

      <h2>Note</h2>
      <ul>
        {batteryCareTips.note.map((note, index) => (
          <li key={index}>{note}</li>
        ))}
      </ul>
    </div>
  );
};

export default BatteryCont;
