import React from "react";
import { Link } from "react-router-dom";

import {
  Divider,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SellingPointsSection from "./sell";

const ProductCard = ({ title, imageSrc, routeTo }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          minHeight: "200px",
          maxHeight: "400px",
          padding: "16px",
          marginBottom: "20px",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)",
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "scale(1.04)",
          },
        }}
      >
        <CardActionArea component={Link} to={routeTo}>
          <CardMedia
            component="img"
            alt={title}
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
            }}
            image={imageSrc}
            title="Hello"
          />
          <CardContent>
            <Typography
              variant="h6"
              component="div"
              style={{ textAlign: "center", paddingTop: "10px" }}
            >
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

function ProductsCardnew() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      style={{
        marginBottom: "50px",
        paddingLeft: isSmallScreen ? "20px" : "20vh",
        paddingRight: isSmallScreen ? "20px" : "20vh",
      }}
    >
      <Typography
        variant="h6"
        style={{
          textAlign: "center",
          fontSize: "2rem",
          marginBottom: "20px",
          marginTop: "10px",
          fontWeight: "bolder",
        }}
      >
        PRODUCTS
      </Typography>
      <Grid container spacing={2}>
        <ProductCard
          title="Lift UPS"
          imageSrc="assets/LiftUPS1.png"
          routeTo="/products/lift"
        />
        <ProductCard
          title="Home UPS"
          imageSrc="assets/upsnew.png"
          routeTo="/products/onlineUps"
        />
        <ProductCard
          title="Lifts"
          imageSrc="assets/liftnew.png"
          routeTo="/products/lift1"
        />
        <ProductCard
          title="Solar Roof Top System"
          imageSrc="assets/solarRoof3.png"
          routeTo="products/SolarRoof"
        />
        <ProductCard
          title="Stabilizers"
          imageSrc="assets/stabaliser3.png"
          routeTo="products/stabilizers"
        />
        <ProductCard
          title="Batteries"
          imageSrc="assets/battery3.png"
          routeTo="/products/battery"
        />
        <ProductCard
          title="SineWave Solar Inverter/ UPS"
          imageSrc="assets/load.jpg"
          routeTo="/products/sinewaveUps"
        />
      </Grid>
      <Divider style={{ width: "100%" }} />
      <SellingPointsSection />
    </div>
  );
}

export default ProductsCardnew;
