
import { Grid, Typography, useMediaQuery,useTheme } from "@mui/material";
import React from "react";
import ServicesForm from "./ServicesForm";
import image from "./service3.jpg"; // Import your image here


function Services() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
       <h1 style={{textAlign:'center'}}>SERVICES</h1>
      <Grid container spacing={2} style={{ flexDirection: "row" }}>
       
        <Grid item xs={12} md={6} style={{ height: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            style={{ height: "100%" }}
          >
            <Grid item style={{ textAlign: "center" }}>
              <img src={image} alt="Service" style={{ maxWidth: "100%", maxHeight: "100%" }} />
            </Grid>
            {/* <Grid item>
              <Typography
                variant="body1"
                style={{ fontSize: "18px", marginBottom: "20px" }}
              >
                Elites Power Solutions is committed to providing unparalleled
                quality service during the entire lifecycle of the products.
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        
        {/* Right Side */}
        <Grid item xs={12} md={6} style={{ height: "100%", padding:'20px',marginTop: '20px',marginLeft:isSmallScreen?'15px':""  }}>
        <Grid item>
              <Typography
                variant="body1"
                style={{ fontSize: "18px", marginBottom: "20px" }}
              >
                Elites Power Solutions is committed to providing unparalleled
                quality service during the entire lifecycle of the products.
              </Typography>
            </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" ,marginBottom: "40px"}}
          >
            <ServicesForm />
          </Grid>

          
        </Grid>
      </Grid>
    </div>
  );
}

export default Services;
