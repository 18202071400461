import React from "react";
import { Grid, useMediaQuery, useTheme, Container } from "@mui/material";
import SingleCard from "./SingleCard";

const ClientList = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const upsClients = [
    "BC Patil",
    "Sharan",
    "Shrusti Patil",
    "Suresh Gowda",
    "Shivshankar Chowdary",
    "Gayathri Devi",
    "Oblesh",
    "M.Krishnappa",
    "KT Nagraj",
    "Ravishankar",
    "Varaprasad Reddy",
    "Mr.Ambresh",
    "Mr.Sudeep",
    "M.R Harish",
    "Rokkappa Gowda",
    "Jayaram Reddy",
    "Prasad Reddy",
    "Srinivas Prasad",
  ];

  const solar = [
    "Arun Somanna",
    "Anand",
    "Tanmay Sir",
    "KM Suresh",
    "Narayan Reddy",
    "MN Gowda",
    "Srikanth",
    "Vijay Kumar",
    "HR Suresh Gowda",
    "Vinod Shehadri",
    "Shaik Saleem Basha",
    "Anitha MS",
    "Rajesh Reddy",
    "Vishwanath Reddy",
  ];

  const lifts = [
    "Aishwarya Builders and Developers",
    "SLV Group",
    "Uday Pramoters & Developers",
    "SLV Shelters Pvt Ltd",
    "Aditi Enterprises",
    "Reliance Builders & Developers",
    "S.A Constructions",
    "Prapoorna Ortho Consultants",
    "Iris Builders & Developers",
    "Shadmaan Trading",
    "AV Multi Speciality Hospital",
    "Siri Homes Builders and Developers",
    "High Tech Engineers",
    "Shubham Architect",
    "SG Developers",
    "Veda Engineers and Constructions",
    "Shivganga infra Pvt Ltd",
    "Shivganga Build Tech",
    "High Tech Constructions",
    "SLV Developers Pvt Ltd",
    "Shiva Constructions",
    "Maheshwari Groups",
    "Sumukha Constructions",
    "Balu Constructions",
    "Indus Groups",
    "Siri Luxury Homes",
    "SR Builders and Developers",
    "Vinay groups",
    "Shikara Builders and Developers",
    "Maha Abhishek fashion",
    "Nithya Brundavana",
    "Nithya NandaGokula",
    "Orange Photography",
    "RG Concrete",
    "Shri Lakshmi Balaji Properties",
    "Shree Lakshmi Balaji Properties",
    "Gowri Shankar Fuel Station",
    "Concept Groups",
    "Clarks Inn Hotel",
    "Air Avenue Hotel",
    "TT Group",
    "S2P Constructions",
    "Avyukth Designers and Constructions",
    "Scita Solutions",
    "RR Enclave",
    "Dungramal Porwal Solutions and Others",
    "Umaye Builders and Developers",
    "Sun Infrastructure",
    "Aditi Infra structure",
    "RR Dream Wood",
    "RR King Space",
    "ATM Reality and Infra",
    "SLV Homes",
    "MRC Constructions",
    "Perfect Point",
    "summner Blue Clothing Pvt Ltd",
    "Saleem Gold Cove and Family",
    "FBO Reality Infra",
  ];

  return (
    <Container
      maxWidth="lg" // Adjust container width based on screen size
      style={{
        position: "relative",
        zIndex: 1,
        marginTop: isMobile ? "14px" : "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={2} // Adjust spacing between cards
        style={{
          marginBottom: isMobile ? "20px" : "20px",
          marginLeft: isMobile ? "20px" : "0px",
        }} // Adjust margin bottom for smaller screens
      >
        <Grid item xs={12} sm={6} md={4}>
          <SingleCard
            backgroundColor="#63B4AE"
            title="VIP Clients"
            description={upsClients}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleCard
            backgroundColor="#63B4AE"
            title="Individual Owners"
            description={solar}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SingleCard
            backgroundColor="#63B4AE"
            title="Builders & Developers"
            description={lifts}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientList;
