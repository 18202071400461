import {
  Container,
  Typography,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
const firstLetterStyle = {
  fontSize: "50px",
  display: "inline-block",
  marginTop: "-20px",
};

const restOfTextStyle = {
  fontSize: "25px",
  display: "inline-block",
  marginLeft: "-5px",
};
const bigQuotesStyle = {
  fontSize: "50px",
  color: "#555",
  marginRight: "5px",
};
const quotesStyle = {
  fontSize: "50px",
  color: "#555",

  marginLeft: "-10px",
};
const specialLineStyle = {
  display: "flex",
  alignItems: "center",
  marginLeft: "10px",
};

const lineStyle = {
  borderTop: "2px solid #333", // Adjust the color and width of the line
  width: "100%", // Set the width of the line to 100%
  margin: "10px",
};

const AboutInfo = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Grid container spacing={2} style={{ marginTop: "20px" }}>
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        style={{
          marginTop: "30px",
          paddingLeft: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1" style={{ textAlign: "center" }}>
          <span style={firstLetterStyle}>W</span>
          <span style={restOfTextStyle}>hat We Do</span>
        </Typography>
        <Grid>
          <div style={specialLineStyle}>
            <hr style={lineStyle} />
          </div>
          <Typography style={{ fontSize: "25px", textAlign: "center" }}>
            Thinking ahead to meet your tomorrow's clean power needs
          </Typography>
          <div style={specialLineStyle}>
            <hr style={lineStyle} />
          </div>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        style={{
          fontFamily: "sans",
          fontSize: "0.5rem",
          textAlign: "left",
          color: "black",
        }}
      >
        <Typography
          style={{
            marginBottom: "40px",
            marginLeft: isSmallScreen ? "0px" : "20px",
            marginTop: "40px",
            textAlign: "justify",
            padding: "10px",
          }}
        >
          Elites Power Solutions Pvt. Ltd. is a team of young and self-motivated
          professionals with vast expertise in Clean Power Management Solutions.
          The company is committed to providing complete, comprehensive, and
          competitive power solutions, including renewable energy, spanning
          basic home applications of power backup to mission-critical
          requirements in Hospitals, Data Centre’s, Banking & Financial
          Institutes, and Emergency Service Centre’s.
        </Typography>
        <Typography
          style={{
            marginBottom: "40px",
            marginLeft: isSmallScreen ? "0px" : "20px",
            marginTop: "20px",
            textAlign: "justify",
            padding: "10px",
          }}
        >
          With its unparalleled commitment to customer service and quality,
          Elites Power has progressed quickly as a preferred partner for key
          customers across the industry verticals. With its State of Art
          Technology Products, Elites assists customers to achieve the highest
          efficiency in their operations.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutInfo;
