
// import React from 'react';
// import { useMediaQuery,useTheme } from '@mui/material';


// const LiftContent = ({contentData}) => {
//   const { features } = contentData.liftContent;
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

//   return (
//     <ul style={{ fontSize:isSmallScreen? '12px' :'18px'}}>
//       {features.map((feature, index) => (
//         <li key={index}>{feature}</li>
//       ))}
//     </ul>
//   );
// };

// export default LiftContent;
import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const LiftContent = ({ contentData }) => {
  const { features } = contentData.liftContent;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ul style={{ fontSize: isSmallScreen ? '12px' : '18px' }}>
      {features.map((feature, index) => {
 
        const [subtitle, description] = feature.split(' - ');

        return (
          <li key={index}>
            <b>{subtitle}</b> - {description}
          </li>
        );
      })}
    </ul>
  );
};

export default LiftContent;
