import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

const applicationsData = [
  "Residential and Commercial Establishments",
  "Individual / Group Homes",
  "Hospitals",
  "Banks and Financial Institutes",
  "Educational Institutions",
  "Lab & Research Centre’s",
  "Manufacturing Plants",
  "Solar Power Generating Plants",
];

const KeyApplicationsSection = () => {
  return (
    <Container style={{ marginTop: "20px" }}>
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginBottom: 3,
          color: "black",
          fontSize: "2rem",
          fontWeight: "bold",
        }}
      >
        Explore Our Power Solutions Across Diverse Sectors:
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        {[0, 1].map((colIndex) => (
          <Grid item key={colIndex} xs={12} sm={6}>
            <Box
              sx={{
                textAlign: "start",
                p: { xs: 0.5, sm: 2 },
                color: "darkblue",
              }}
            >
              {applicationsData.map(
                (app, index) =>
                  index % 2 === colIndex && (
                    <div style={{ display: "flex", flexDirection: "col" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "black",
                          marginRight: "10px",
                          marginTop: "10px",
                        }}
                      />
                      <Typography
                        key={index}
                        variant="h6"
                        sx={{
                          color: "darkblue",
                          textAlign: "start",
                        }}
                      >
                        {app}
                      </Typography>
                    </div>
                  )
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default KeyApplicationsSection;
