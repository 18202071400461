// import React, { useEffect } from 'react'

// function Stabilizers() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (

//     <div>Stabilizers</div>
//   )
// }

// export default Stabilizers
import React from "react";
import { Grid, Typography } from "@mui/material";

function Stabilizers() {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh", backgroundColor: "#f9f9f9" }}
    >
      <Grid item xs={12}>
        <Typography variant="h2" align="center" style={{ color: "#3f51b5" }}>
          Our New Product Is Coming Soon!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          align="center"
          style={{ color: "#666", fontSize: "1.2rem" }}
        >
          Stay tuned for exciting updates!
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Stabilizers;
