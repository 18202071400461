import React from "react";
import { Grid, Typography } from "@mui/material";
import ContactForm from "./form";
import EmailIcon from "@mui/icons-material/Email";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";

const ContactUs = () => {
  return (
    <Grid container sx={{ marginTop: { xs: "20px", md: "50px", lg: "100px" } }}>
      <ContactForm />
      <Grid
        container
        justifyContent="space-around"
        sx={{
          textAlign: "center",
          backgroundColor: "#f5f6fa",
          marginTop: { xs: "20px", md: "50px" },
          padding: "20px",
        }}
      >
        <Grid item xs={12} md={4}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "6px",
              justifyContent: "center",
            }}
          >
            <RoomIcon style={{ marginRight: "5px" }} />
            <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
              Registered Office
            </Typography>
          </div>
          <Typography>
            Elites Power Solutions Pvt. Ltd. <br />
            # 459, Vaishnavi Enclave, <br />
            12th Main, MC Layout <br />
            Near Post Office, Bengaluru, Karnataka.
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <EmailIcon style={{ marginRight: "5px" }} />
              <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
                Email Us:
              </Typography>
            </div>
            <Typography>For all global queries</Typography>
            <a
              style={{
                fontWeight: "bold",
                textDecoration: "none",
                color: "black",
              }}
              href="mailto:sales@elitespower.com"
            >
              sales@elitespower.com
            </a>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <PhoneIcon style={{ marginRight: "5px" }} />
              <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
                Call Us:
              </Typography>
            </div>
            {/* <Typography>Directors of Elites</Typography> */}
            <div style={{ flexWrap: "wrap" }}>
              <Typography>Mr. Ambreshwar Kadale</Typography>
              <Typography>
                <a
                  href="tel:+918880123444"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  +91-8880123444
                </a>
              </Typography>
            </div>
            <div style={{ flexWrap: "wrap" }}>
              <Typography>Mrs. Roopa Rudraswamy</Typography>

              <Typography>
                <a
                  href="tel:+919972544240"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  {" "}
                  +91-9972544240
                </a>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          <h2>We are here &darr;</h2>
        </Typography>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124441.36695446334!2d77.62739171471073!3d12.921040032990826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f42b40f23c7%3A0xca41319b17cd3aa4!2sElites%20Power%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1705984879269!5m2!1sen!2sin"
          width="100%"
          height="350"
          allowfullscreen=""
          frameBorder="0"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
