import React, { useEffect } from "react";
import Banner from "./Banner";
import ThumbnailGallery from "./ImageViewer";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import LiftContent from "./LiftContent1";
import TabComponent from "./LiftTab";
import OptionalFeature from "./OptFeatures";
import DataTable from "./LiftTable";
import data1 from "./OnlineContent.json";
import imageData1 from "./OnlineContent.json";
import contentData1 from "./OnlineContent.json";
import tabsData1 from "./OnlineContent.json";
import data2 from "./OnlineContent.json";
import data3 from "./OnlineContent.json";
import onlineTitle from "./OnlineContent.json";
import pdf from "./UPSBrochure.pdf";
const OnlineUps = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const iframeWidth = isMobile ? "100%" : "60%";
  const iframeHeight = isMobile ? "800px" : "1000px";
  return (
    <Grid>
      <Banner data={data1} />
      <onlineTitle />
      <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ padding: "10px", textAlign: 'center' }}> 
          <h3>Technical Specification (Range 3 kva to 150 kva) Technology</h3>
          <h4>
            IGBT BASED Double conversion Isolation Transformer Technology <br /> –
            Latest Microcontroller based PWM Generation.
          </h4>
        </div>
        </Grid>
        <Grid item xs={12} md={6} style={{padding:'20px'}}>
          <ThumbnailGallery imageData={imageData1.images} />
        </Grid>
        <Grid item xs={12} md={6}>
          <h2>Salient Features of Home UPS.</h2>
          <LiftContent contentData={contentData1} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <TabComponent tabsData={tabsData1} />
      </Grid>

    </Grid>
  );
};
export default OnlineUps;
