import React from 'react';
import { Container, Typography, Grid, Box, Divider } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PowerIcon from '@mui/icons-material/Power';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';

const featuresData = [
  { icon: <VerifiedUserIcon fontSize="large" />, title: 'Built-in EARD', description: 'Emergency Auto Rescue Device for user safety' },
  { icon: <PowerIcon fontSize="large" />, title: 'IGBT Technology', description: 'Over 99% uptime and reduced breakdowns' },
  { icon: <BatteryChargingFullIcon fontSize="large" />, title: '3 Phase Charger', description: 'Greater efficiency during heavy charging load' },
  { icon: <MultilineChartIcon fontSize="large" />, title: 'High Power Factor', description: 'Enhanced energy efficiency' },
  { icon: <SettingsEthernetIcon fontSize="large" />, title: 'Auto Phase Reverse Correction', description: 'Ensures proper phase alignment' },
  { icon: <FlashOnIcon fontSize="large" />, title: 'Higher Safety', description: 'Avoids possible shocks at battery terminals' },
  // { icon: <GraphicEqIcon fontSize="large" />, title: 'Graphical Display', description: '"Easy to read" display for Lift UPS' },
];

const KeyFeaturesSection = () => {
  return (
    <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 2, color: 'black', fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }}>
        Safety and Performance Combined: Explore Our Advanced Features:
      </Typography>
      <Grid container spacing={0}>
        {featuresData.map((feature, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box
                sx={{
                  textAlign: 'center',
                  p: { xs: 0.5, sm: 2 },
                  color: 'darkblue',
                  width: '100%',
                  backgroundColor: 'white',
                  marginLeft: '40px',
                  marginLeft: { xs: '10px', sm: '40px' },
                }}
              >
                {feature.icon}
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2">{feature.description}</Typography>
              </Box>
            </Grid>
            {(index % 3 !== 2) && (
              <Grid item xs={0} sm={0} md={0} lg={1}>
                <Divider sx={{ height: '100%', backgroundColor: 'light grey' }} orientation="vertical" />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Container>
  );
};

export default KeyFeaturesSection;



