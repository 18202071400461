
import React from 'react';
import { useMediaQuery,useTheme } from '@mui/material';


const LiftContent = ({contentData}) => {
  const { features } = contentData.liftContent;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ul style={{ fontSize:isSmallScreen? '12px' :'18px'}}>
      {features.map((feature, index) => (
        <li key={index}><b>{feature}</b></li>
      ))}
    </ul>
  );
};

export default LiftContent;