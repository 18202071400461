import React, { useEffect } from "react";
import Banner from "./Banner";
import ThumbnailGallery from "./ImageViewer";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import LiftContent from "./LiftContent";
import TabComponent from "./LiftTab";
import OptionalFeature from "./OptFeatures";
import DataTable from "./LiftTable";
import data1 from "./ListContent.json";
import imageData1 from "./ListContent.json";
import contentData1 from "./ListContent.json";
import tabsData1 from "./ListContent.json";
import data2 from "./ListContent.json";
import data3 from "./ListContent.json";

const Lift = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isMobile = useMediaQuery("(max-width: 600px)");
  
  return (
    <Grid>
      <Banner data={data1} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ padding: "10px", textAlign: "center" }}>
            <h3>Technical Specification (Range 6 kva to 120 kva) Technology</h3>
            <h4 style={{ marginTop: "-1em" }}>
              IGBT BASED Double conversion Isolation Transformer Technology{" "}
              <br /> – Latest Microcontroller based PWM Generation.
            </h4>
          </div>
        </Grid>

        <Grid item xs={12} md={5} style={{padding:'20px'}}>
          <ThumbnailGallery imageData={imageData1.images} />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          style={{ marginTop: "-10px", marginLeft: "20px" }}
        >
          <h2>Salient Features of Lift UPS.</h2>
          <LiftContent contentData={contentData1} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ overflowX: "auto" }}>
        <TabComponent tabsData={tabsData1} />
      </Grid>

      <Grid item xs={12} md={6}>
        <DataTable data={data3} />
      </Grid>
      {/* <Grid
        alignItems="center"
        justify="center"
        style={{
          marginBottom: "10px",
        }}
      >
        <Grid item style={{textAlign: 'center',padding:'20px',marginBottom:'10px',marginTop:'10px'}}>
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Lift Brochure
          </Typography>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>

          <iframe
            src={pdf}
            width={iframeWidth}
            height={iframeHeight}
            style={{ border: "none",margin: "auto"  }}
          />
        </div>
      </Grid> */}
      <Grid
        alignItems="center"
        justify="center"
        style={{
          marginBottom: "10px",
        }}
      ></Grid>
    </Grid>
  );
};
export default Lift;
